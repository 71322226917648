export default class API {
  static baseUrl = process.env.REACT_APP_API_BASE_URL;

  static dashboards = API.baseUrl + '/dashboards';

  static mitreDashboard = API.baseUrl + '/mitre-dashboard';

  static login = API.baseUrl + '/login';

  static forgotPassword = API.baseUrl + '/users/forgot-password';

  static resetPassword = API.baseUrl + '/users/reset-password?token=';

  static invitedUser = API.baseUrl + '/users/invited-user';

  static verifyCode = API.baseUrl + '/users/verify-code';

  static changePassword = API.baseUrl + '/users/change-password';

  static googleAuthProfile = API.baseUrl + '/users/google-auth-profile';

  static generateKey = API.baseUrl + '/users/generate-api-key';

  static logout = API.baseUrl + '/users/logout';

  static cases = API.baseUrl + '/cases';

  static help = API.baseUrl + '/sirp-help';

  static casesCategory = API.baseUrl + '/custom-functions/categories?type=advisory';

  static searchCases = API.baseUrl + '/cases/search?iti_type=';

  static filterCase = API.baseUrl + '/cases/filter';

  static dispositions = API.baseUrl + '/custom-functions/disposition';

  static asset = API.baseUrl + '/asset';

  static exportJob = API.baseUrl + '/export-jobs';

  static assetSearch = API.baseUrl + '/asset/search';

  static assetClassification = API.baseUrl + '/asset-classification';

  static assetConfidentiality = API.baseUrl + '/asset/confidentiality-list';

  static assetAvailability = API.baseUrl + '/asset/availability-list';

  static assetIntegrity = API.baseUrl + '/asset/integrity-list';

  static assetOS = API.baseUrl + '/asset/os-list';

  static assetImport = API.baseUrl + '/asset/import';

  static assetRulesSearch = API.baseUrl + '/asset-rules/search';

  static assetType = API.baseUrl + '/asset-type';

  static assetRules = API.baseUrl + '/asset-rules';

  static networkBlocks = API.baseUrl + '/network';

  static assetRulesResults = API.baseUrl + '/asset-rule-results';

  static assetValue = API.baseUrl + '/asset-value';

  static assetOwners = API.baseUrl + '/asset-owners';

  static department = API.baseUrl + '/asset/departments';

  static assetStatus = API.baseUrl + '/asset/asset-status';

  static assetRemoteApp = API.baseUrl + '/asset/asset-remote-app';

  static locations = API.baseUrl + '/custom-functions/location';

  static userProfile = API.baseUrl + '/users/profile';

  static userQr = API.baseUrl + '/users/google-qr-image';

  static organizations = API.baseUrl + '/asset-os';

  static playbooks = API.baseUrl + '/playbooks';

  static playbooksMarketplace = API.baseUrl + '/playbooks/market-place';

  static playbooksLogs = API.baseUrl + '/playbooks-queue';

  static playbookRules = API.baseUrl + '/playbook-rules';

  static playbookCustomExecute = API.baseUrl + '/custom-functions/execute';

  static playbookImport = API.baseUrl + '/playbooks/import';

  static assetDetailsDashboard = API.baseUrl + '/asset/s3-dashboard';

  static assetCategories = API.baseUrl + '/custom-functions/get-categories';

  static assetEnableFields = API.baseUrl + '/asset/enable-fields';

  static incidentManagement = API.baseUrl + '/incident-management';

  static assetSystemTypes = API.baseUrl + '/asset/system-types';

  static assetNetworkTypes = API.baseUrl + '/asset/network-types';

  static assetPowerStatus = API.baseUrl + '/asset/power-status';

  static assetDocumentTypes = API.baseUrl + '/asset/document-types';

  static assetSiems = API.baseUrl + '/asset/siems';

  static assetZones = API.baseUrl + '/asset/zones';

  static assetProducts = API.baseUrl + '/custom-functions/products';

  static assetVendors = API.baseUrl + '/custom-functions/vendors';

  static assetFields = API.baseUrl + '/asset/category-fields';

  static assetServers = API.baseUrl + '/asset/server';

  static googleLogin = API.baseUrl + '/google-login/login';

  static masterLogin = API.baseUrl + '/google-login/mssp-login';

  static vulnerabilityManagement = API.baseUrl + '/vulnerability-management';
  static vulnerabilityAssessment = API.baseUrl + '/vulnerability-management/assessment';

  static riskAssessment = API.baseUrl + '/risk-Assessment'; // change Static one

  static reports = API.baseUrl + '/reports';
  static report = API.baseUrl + '/report';

  static customFunctions = API.baseUrl + '/custom-functions';


  static incidentManagementModule = {
    update: API.incidentManagement,
    taskUsers: API.baseUrl +"/custom-functions/users",
    comment: {
      post: `${API.incidentManagement}/comment`,
      delete: `${API.incidentManagement}/delete-comment`,
      deleteAttchement: `${API.incidentManagement}/delete-comment-attachment`,
    },
    evidence: {

      delete: `${API.incidentManagement}/delete-attachment`,

    },
    task: {
      create: `${API.incidentManagement}/tasks`,
      categories: `${API.baseUrl}/custom-functions/task/categories`,
      departments: `${API.baseUrl}/custom-functions/departments?type=task`,
    },
    asset: {
      deleteCall: `${API.incidentManagement}/custom-delete`,
    },
    member: {
      add: `${API.incidentManagement}/update-members`,
    },
    master: {
      severity: `${API.baseUrl}/custom-functions/severity`,
      category: `${API.baseUrl}/custom-functions/categories`,
      subCategory: `${API.baseUrl}/custom-functions/sub-category`,
      disposition: `${API.baseUrl}/custom-functions/disposition?isIncident=true`,
      subDisposition: `${API.baseUrl}/custom-functions/sub-disposition`,
      location: `${API.baseUrl}/custom-functions/location`,
      detectionMethods: `${API.baseUrl}/custom-functions/detection-methods`,
      artifacts: `${API.incidentManagement}/artifacts`,
      artifactList: `${API.baseUrl}/custom-functions/artifact-list`,
      lessonLearned: `${API.baseUrl}/custom-functions/lesson-learned`,
      containedBy: `${API.baseUrl}/custom-functions/contained-by`,
      containmentStatus: `${API.baseUrl}/custom-functions/containment-status`,
    },
    export: {
      pdf: `${API.incidentManagement}/export`,
    },
    actionApp: {
      list: `${API.baseUrl}/custom-functions/dispense?expand=actApp`,
      commentAppconfig: `${API.incidentManagement}/comment-app-config`,
    },
    runActions: API.customFunctions + '/run-action',
    runMultiConfigActions: `${API.customFunctions}/select-config`,
    artifact: {
      update: API.customFunctions + '/artifact-value-update',
      add: `${API.incidentManagement}/add-artifact`,
    },
    rowOutput: `${API.incidentManagement}/get-raw-output`,
    reportType: `${API.incidentManagement}/reports-list`,
    generateReport: `${API.incidentManagement}/reports`,
    dispositionKey: `${API.incidentManagement}/disposition-key`,
    dispositionFields: `${API.incidentManagement}/disposition-fields`,
    bulkUpdate: `${API.incidentManagement}/bulk-update`,
    bulkExecute: `${API.baseUrl}/custom-functions/bulk-execute?module=incident`,
    bulkDelete: `${API.incidentManagement}/bulk-delete`,
  };

  static userModule = {
    list: `${API.baseUrl}/users`,
  };

  static advisory = API.baseUrl + '/advisory';

  static advisoryImport = API.baseUrl + '/advisory/import';

  static advisoryArtifactUpdate =
    API.customFunctions + '/artifact-value-update';

  static advisoryAddEvidence = API.baseUrl + '/advisory/add-evidence';
  static advisoryArtifactExectuteAction = API.baseUrl + '/custom-functions/run-action';

  static advisoryArtifactMultiConfigExectuteAction =
    API.baseUrl + '/custom-functions/select-config';

  static advisoryArtifactRawOutput = API.baseUrl + '/advisory/get-raw-output';

  static advisoryCustomUpdate = API.baseUrl + '/advisory/custom-update';

  static advisoryPartialCustomUpdate = API.baseUrl + '/incident-management/custom-update';

  static advisoryCategory = API.baseUrl + '/advisory/category';
  static advisorySubCategory = API.baseUrl + '/custom-functions/sub-category';
  static advisoryCaseCategory = API.customFunctions + '/categories?type=advisory';

  static casesSubcategories = API.customFunctions + '/sub-category';

  static advisorySeverity = API.baseUrl + '/custom-functions/severity';

  static assetList = API.baseUrl + '/custom-functions/asset';

  static advisoryDepartments = API.baseUrl + '/custom-functions/departments';

  static advisoryEvidence = API.baseUrl + '/custom-functions/artifact-list';

  static advisoryDispositions = API.baseUrl + '/advisory/dispositions';

  //static openCase = API.baseUrl + '/advisory/open-case';
  static openCase = API.baseUrl + '/cases-advisory';

  static CasessubDispositions = API.baseUrl + '/cases/subdispositions';

  static advisoryProducts = API.baseUrl + '/custom-functions/products';

  static advisoryVendors = API.baseUrl + '/custom-functions/vendors';

  static advisorySources = API.baseUrl + '/custom-functions/sources?type=Advisory';

  static advisoryThreatActors = API.baseUrl + '/advisory/threat-actors';

  static caseAdvisoryItems = API.baseUrl + '/cases-advisory/all-adv';

  static caseAdvisory = API.baseUrl + '/cases-advisory';
  static caseVm = API.baseUrl + '/cases-vulnerability';

  static casesAdvisorylocation = API.customFunctions + '/location';

  static casesAdvisoryUserList = API.baseUrl + '/cases-advisory/users';

  static advisoryExport = API.baseUrl + '/advisory/export';

  static advisoryPlayBook = API.baseUrl + '/custom-functions/playbook';

  static incidentPlayBook = API.baseUrl + '/custom-functions/playbook';
  static advisoryGetPlayBook = API.baseUrl + '/custom-functions/playbooks';



  static advisoryUserEmail = API.baseUrl + '/custom-functions/user-emails';

  static advisorySendEmail = API.customFunctions + '/email';

  static applications = API.baseUrl + '/applications';

  static applicationAction = API.baseUrl + '/application-actions';

  static applicationsDisable = API.baseUrl + '/applications/disable';

  static applicationsConfig = API.baseUrl + '/applications/config-data';

  static applicationsVendorsList = API.baseUrl + '/applications/vendors';

  static applicationsPublishers = API.baseUrl + '/applications/publishers';

  static applicationsType = API.baseUrl + '/applications/type';

  static applicationsRateLimit = API.baseUrl + '/applications/rate-limit';

  static applicationsRateLimit = API.baseUrl + '/applications/rate-limit';

  static applicationsApprovalWorkFlow =
    API.baseUrl + '/applications/approval-workflow';

  static applicationsPrimaryApproversList =
    API.baseUrl + '/custom-functions/users';

  static applicationApprovaldeleteWorkFlow = API.baseUrl + '/approval-workflow';

  static applicationsConfigIntegrateData =
    API.baseUrl + '/applications/integrate-data';

  static applicationsConfigIntegrate = API.baseUrl + '/applications/integrate';

  static applicationsIntegrateMultiData =
    API.baseUrl + '/applications/integrate-multi-data';

  static applicationsIntegrateMulti =
    API.baseUrl + '/applications/integrate-multi';

  static reportsModule = `${API.baseUrl}/report`;
  static graphsList = `${API.baseUrl}/dashboards/all-widget-list`;

  static caseManagementModule = {
    update: API.cases,
    comment: {
      post: `${API.incidentManagement}/comment`,
      delete: `${API.incidentManagement}/delete-comment`,
    },
    task: {
      create: `${API.incidentManagement}/tasks`,
      categories: `${API.incidentManagement}/task/categories`,
      departments: `${API.incidentManagement}/task/departments`,
    },
    asset: {
      deleteCall: `${API.cases}/custom-delete`,
    },
    member: {
      add: `${API.cases}/update-members`,
    },
    master: {
      severity: `${API.cases}/severity`,
      category: `${API.customFunctions}/categories`,
      subCategory: `${API.customFunctions}/sub-category`,
      disposition: `${API.customFunctions}/disposition`,
      subDisposition: `${API.customFunctions}/sub-disposition`,
      location: `${API.customFunctions}/location`,
      detectionMethods: `${API.baseUrl}/custom-functions/detection-methods`,
      department: `${API.incidentManagement}/departments-list`,
    },
    export: {
      pdf: `${API.cases}/export`,
      caseAdvisoryPdf: `${API.caseAdvisory}/pdf`,
      caseVmPdf: `${API.caseVm}/export-pdf`,


    },
    actionApp: {
      list: `${API.baseUrl}/custom-functions/dispense?expand=actApp`,
    },
    runActions: `${API.customFunctions}/run-action`,
    artifact: {
      update: `${API.cases}/artifact-value-update`,
      add: `${API.incidentManagement}/add-artifact`,
    },
    risk: {
      list: `${API.cases}/cases-risk`,
    },
    vulnerability: {
      list: `${API.cases}/cases-vulnerability`,
    },
  };
  static automationList = API.baseUrl + '/automation-playground';
  static artifactImport = API.baseUrl + '/automation-playground/artifacts/import';
  static administration = API.baseUrl + '/administration';

  static administrationModule = {
    organization: API.administration + '/organization',
    assets: API.administration + '/assets',
    workflow: API.administration + '/workflow',
    category: API.administration + '/workflow/tasks-categories',
    workflowStatus: API.administration + '/workflow/change-status',
    assetGroups: API.administration + '/assets/assets-group',
    riskManagement: API.administration + '/risk-management',
    riskManagementThreatRegister:
      API.administration + '/risk-management/threat-register',
    riskManagementThreatValue:
      API.administration + '/risk-management/threat-value',
    riskManagementControlRegister:
      API.administration + '/risk-management/control-register',
    riskManagementBusinessImpact:
      API.administration + '/risk-management/impact',
    riskManagementBusinessImpactChangeTitle:
      API.administration + '/risk-management/impact/change-title',
    riskManagementCompliance:
      API.administration + '/risk-management/compliance',
    automationList: API.administration + '/automation',
    automationVendorsList: API.administration + '/automation/vendors',
    automationPublishers: API.administration + '/automation/publishers',
    automationActionsIO: API.administration + '/automation/io-types',
    automationThreat: API.administration + '/automation/threat-feeds',
    automationFamily: API.administration + '/automation/use-case-family',
    automationIngestionSource:
      API.administration + '/automation/ingestion-source',
    automationIngestionRule:
      API.administration + '/automation/pre-ingestion-rule',
    ingestionType:
      API.administration + '/automation/ingestion-source/ingestion-type',
    ingestionWidget:
      API.administration + '/automation/ingestion-source/widget-list',
    ingestionFrequence:
      API.administration + '/automation/ingestion-source/frequency',
    ingestionApplication:
      API.customFunctions + '/application-list',
    ingestionUsers:
      API.administration + '/automation/ingestion-source/users-list',
    enableAdvisory:
      API.administration + '/automation/ingestion-source/enable-advisory',
    ingestionAction:
      API.administration + '/automation/ingestion-source/app-actions',
    riskManagementValues: API.administration + '/risk-management/threat-value',
    technicalVulnerability:
      API.administration +
      '/vulnerability-management/technical-vulnerabilities',
    nonTechnicalVulnerability:
      API.administration +
      '/vulnerability-management/non-technical-vulnerabilities',
    vulnerabilitySeverityList:
      API.administration +
      '/vulnerability-management/technical-vulnerabilities/severity-list',
    vulnerabilityNonTechnicalSeverityList:
      API.administration +
      '/vulnerability-management/non-technical-vulnerabilities/severity-list',

    vulnerabilitySeverityLevelList:
      API.administration +
      '/vulnerability-management/severity-order',

    vulnerabilityNonTechnicalSeverityLevel:
      API.administration +
      '/vulnerability-management/severity-list',

    vulnerabilityThreatList:
      API.administration +
      '/vulnerability-management/non-technical-vulnerabilities/threat-list',
    threatIntelCategory: API.administration + '/threat_intel/category',
    threatIntelSubCategory: API.administration + '/threat_intel/subcategories',
    threatIntelSubCategorySeverity:
      API.administration + '/threat_intel/subcategories/severity',
    threatIntelSubCategoryPrimaryCategory:
      API.administration + '/threat_intel/subcategories/category-list',
    threatIntelDispositions: API.administration + '/threat_intel/dispositions',
    automationActions: API.administration + '/automation/actions',
    cases: API.administration + '/cases',
    automationActionsType: API.administration + '/automation/actions/type',
    automationActionsScriptType:
      API.administration + '/automation/actions/script-type',
    automationActionsIOType: API.administration + '/automation/actions/io-type',
    automationActionsApplicationType:
      API.administration + '/automation/actions/application-list',
    ingestionMapping:
      API.administration + '/automation/ingestion-source/mapping',
    ingestionMultiData:
      API.administration + '/automation/ingestion-source/integrate-multi-data',
    ingestionMultiDataPost:
      API.administration + '/automation/ingestion-source/integrate-multi',
    logsApps: API.customFunctions + '/administration/logs/app-logs',
    logsAuthentication: API.administration + '/logs/authentication-logs',
    logsActivity: API.administration + '/logs/activity-logs',
    logsIngestion: API.administration + '/logs/ingestion-logs',
    logsPreIngestion: API.administration + '/logs/pre-ingestion-logs',
    productsRegister:
      API.administration + '/product-settings/failed-registrations',
    alertsNotify:
      API.administration + '/product-settings/users/alerts-notify-users',
    accessControlUsers: API.administration + '/access-control/users',
    accessUsersAuthenticationTypes:
      API.administration + '/access-control/users/authentication-type',
    accessUsersPermission:
      API.administration + '/access-control/users/permissions',
    accessUsersDashboards:
      API.dashboards  + '/dashboard-list?fromRole=true',
    accessUsersPasswordReset:
      `${API.administration}/access-control/users/user-reset-password`,
    accessUsersPasswordExpire:
      `${API.administration}/access-control/users/reset-expired-password`,
    accessUsersLandingPage:
      API.administration + '/access-control/users/landing-page',
    accessUsersGroup: API.administration + '/access-control/users/group',
    accessSessions: API.administration + '/access-control/sessions',
    accessControlGroups: API.administration + '/access-control/groups',
    accessControlRoles: API.administration + '/access-control/roles',
    accessControlActions: API.administration + '/access-control/actions',
    accessControlActionsGroupsTab: API.administration + '/access-control/action-groups',
    accessControlAuthSetting: API.administration + '/access-control/auth-setting',
    accessControlAuthPrivileges: API.administration + '/access-control/privileges',
    accessControlAllPrivileges: API.administration + '/access-control/action-groups/group-wise-actions',
    accessControlProductSetting: API.administration + '/product-settings',
    threatActor: API.administration + '/threat_intel/threat-actors',
    associateThreatActor: API.administration + '/threat_intel/threat-actors/associated-groups',
    associatedThreatActor: API.administration + '/threat_intel/associated-threat-actors',
    updateLogNotification: API.administration + '/logs/notifications',
    affectedVendor: API.administration + '/threat_intel/threat-vendor',
    affectedProduct: API.administration + '/threat_intel/threat-product',
  };

  static vulnerabilityManagementModule = {
    vulnerabilityManagementList: API.vulnerabilityManagement
  };
  static vulnerabilityAssessmentManagementModule = {
    vulnerabilityManagementAssessmentList: API.vulnerabilityAssessment
  };
  static vulnerabilityTaskdepartments = {
    VulnerabilityTask: API.vulnerabilityManagement + '/task/departments'
  };
  static vulnerabilityCustomUpdate = API.baseUrl + '/vulnerability-management/custom-update';

  static riskAssessmentModule = {
    riskAssessmentList: API.riskAssessment
  }

  static rportsModule = {
    reportsList: API.reports
  }
  static article = API.baseUrl + '/article';
  static gridViewFields = API.baseUrl + '/gridview-fields';
  static gridViewFieldsModule = {
    setting: `${API.gridViewFields}/setting`,
    getGridView: `${API.gridViewFields}/view-grid`,

  }
}
