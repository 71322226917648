export const pageCountList = [
  {
    key: '10',
    value: '10',
  },
  {
    key: '20',
    value: '20',
  },
  {
    key: '30',
    value: '30',
  },
  {
    key: '40',
    value: '40',
  },
  {
    key: '50',
    value: '50',
  },
];

export const dateFormat = 'YYYY-MM-DD';

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const severityFilter = [
  {
    key: 'all',
    value: 'All',
  },
  {
    key: 'high',
    value: 'High',
  },
  {
    key: 'medium',
    value: 'Medium',
  },
  {
    key: 'low',
    value: 'Low',
  },
];

export const showingFilter = [
  {
    key: '10',
    value: '10',
  },
  {
    key: '20',
    value: '20',
  },
  {
    key: '30',
    value: '30',
  },
  {
    key: '40',
    value: '40',
  },
  {
    key: '50',
    value: '50',
  },
];

export const incidentShowingFilter = [
  {
    key: '20',
    value: '20',
  },
  {
    key: '50',
    value: '50',
  },
  {
    key: '100',
    value: '100',
  },
  {
    key: '150',
    value: '150',
  },
  {
    key: '250',
    value: '250',
  },
];

export const casesShowingFilter = [
  {
    key: '20',
    value: '20',
  },
  {
    key: '50',
    value: '50',
  },
  {
    key: '100',
    value: '100',
  },
  {
    key: '150',
    value: '150',
  },
  {
    key: '250',
    value: '250',
  },
];

export const assetShowingFilter = [
  {
    key: '10',
    value: '10',
  },
  {
    key: '20',
    value: '20',
  },
  {
    key: '50',
    value: '50',
  },
  {
    key: '100',
    value: '100',
  },
  {
    key: '250',
    value: '250',
  },
  {
    key: '500',
    value: '500',
  },
];

export const statusFilter = [
  {
    key: 'all',
    value: 'All',
  },
  {
    key: 'Open',
    value: 'Open',
  },
  {
    key: 'Close',
    value: 'Close',
  },
];

export const incidentStatusFilter = [
  {
    key: 'all',
    value: 'All',
    label: 'All'
  },
  {
    key: 'Open',
    value: 'Open',
    label: 'Open'
  },
  {
    key: 'Close',
    value: 'Close',
    label: 'Close'
  },
  {
    key: 'Deferred',
    value: 'Deferred',
    label: 'Deferred'
  },
  {
    key: 'Waiting',
    value: 'Waiting',
    label: 'Waiting'
  },
  {
    key: 'In Progress',
    value: 'In Progress',
    label: 'In Progress'
  },
  {
    key: 'Solved',
    value: 'Solved',
    label: 'Solved',
  },
  {
    key: 'Pending',
    value: 'Pending',
    label: 'Pending',
  },
  {
    key: 'No Response',
    value: 'No Response',
    label: 'No Response',
  },
  {
    key: 'Closed with resolution',
    value: 'Closed with resolution',
    label: 'Closed with resolution',
  },
  {
    key: 'Closed without resolution',
    value: 'Closed without resolution',
    label: 'Closed without resolution',
  },
];

export const incidentStatusList = [
  {
    key: 'all',
    value: 'All',
    label: 'All'
  },
  {
    key: 'Open',
    value: 'Open',
    label: 'Open',
  },
  {
    key: 'Close',
    value: 'Close',
    label: 'Close',
  },
  {
    key: 'Deferred',
    value: 'Deferred',
    label: 'Deferred',
  },
  {
    key: 'Waiting',
    value: 'Waiting',
    label: 'Waiting',
  },
  {
    key: 'In Progress',
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    key: 'Solved',
    value: 'Solved',
    label: 'Solved',
  },
  {
    key: 'Pending',
    value: 'Pending',
    label: 'Pending',
  },
  {
    key: 'No Response',
    value: 'No Response',
    label: 'No Response',
  },
  {
    key: 'Closed with resolution',
    value: 'Closed with resolution',
    label: 'Closed with resolution',
  },
  {
    key: 'Closed without resolution',
    value: 'Closed without resolution',
    label: 'Closed without resolution',
  },
];

export const vmStatusList = [
  { key: 'all', value: 'All', label: 'All' },
  { key: 'open', value: 'Open', label: 'Open' },
  { key: 'close', value: 'Close', label: 'Close' },
  { key: 'deferred', value: 'Deferred', label: 'Deferred' }
];

export const reportTypes = [
  { key: 'excel-t1', value: 'excel-t1', label: 'Excel Template 1' },
  { key: 'excel-t3', value: 'excel-t3', label: 'Excel Template 2' },
  { key: 'excel-export-data', value: 'excel-export-data', label: 'Excel Template 3' },
  { key: 'excel-t2', value: 'excel-t2', label: 'Threat Register' },
];

export const automiationReportTypes = [
  { key: 'excel-auto-play-t1', value: 'excel-auto-play-t1', label: 'Executed Actions' },
];


export const advisoryStatusList = [
  { key: 'all', value: 'All', label: 'All' },
  { key: 'open', value: 'Open', label: 'Open' },
  { key: 'close', value: 'Close', label: 'Close' },
  { key: 'deferred', value: 'Deferred', label: 'Deferred' },
  { key: 'Advisory Disseminated', value: 'Advisory Disseminated', label: 'Advisory Disseminated' },
  { key: 'Not mitigated due to end of life', value: 'Not mitigated due to end of life', label: 'Not mitigated due to end of life' },
  { key: 'Not mitigated due to no AMC', value: 'Not mitigated due to no AMC', label: 'Not mitigated due to no AMC' },
  { key: 'Not mitigated to other reasons', value: 'Not mitigated to other reasons', label: 'Not mitigated to other reasons' },
  { key: 'Workaround applied', value: 'Workaround applied', label: 'Workaround applied' },
  { key: 'Temporary workaround applied until the patch released ', value: 'Temporary workaround applied until the patch released ', label: 'Temporary workaround applied until the patch released ' },
];

export const reportDispositions = [
  { key: "all", value: 'all', label: 'All' },
  { key: 1, value: '1', label: 'Incidents' },
  { key: 3, value: '3', label: 'Investigation' },
  { key: 5, value: '5', label: 'Alert' },

];

export const graphColors = ['#FF31EB', '#FFC442', '#1E75FF', '#40c4ff', '#3DD598', '#ff6d00', '#795548', '#607d8b', '#d50000'];

export const priorityFieldColors = {
  High: '#FF2F3E',
  Medium: '#FFAF00',
  Low: '#9FEF00',
  Critical: '#B80505',
  Severe: '#9400D3',
  Moderate: '#333333',
  None: '#228B22'
};

export const statusFieldColors = {
  Open: '#A20E0E',
  Close: '#2dcc70',
  Deferred: '#2C7873',
  'In progress': '#F19D03',
}

export const preIngestionRuleList = [
  {
    key: 'Link and Update',
    value: 'Link and Update',
    label: 'Link and Update'
  }
]


export const playbookStatuses = [
  { key: 'all', value: 'All', label: 'All' },
  { key: 'enable', value: 'Enable', label: 'Enable' },
  { key: 'disable', value: 'Disable', label: 'Disable' }
]

export const typeInputOptions = [
  {
    key: 'default',
    value: 'string',
    label: 'String',
  },
  {
    key: 2,
    value: 'password',
    label: "Password"
  }
]

export const requiredOptions = [
  { key: 'default', value: 'yes', label: "Yes" },
  { key: 2, value: 'no', label: "No" }
]

export const assetReportList = [
  { key: 'template1', value: 'template1', label: 'Template 1' },
  { key: 'template2', value: 'template2', label: 'Template 2' },
];

export const assetsDetails = [
  {
    label: 'Business Group',
    key: 'assetBusinessGroup',
    data: "dashboardData"
  },
  {
    label: 'Asset group',
    key: 'assetGroup',
    data: "dashboardData"
  },
  {
    label: 'Asset Subgroup',
    key: 'assetSubGroup',
    data: "dashboardData"
  },
  {
    label: 'Asset Type',
    key: 'assetType',
    data: "dashboardData"
  },
  {
    label: 'Name',
    key: 'ast_name',
  },
  {
    label: 'Owner',
    key: 'assetOwner',
    data: 'dashboardData'
  },
  {
    label: 'NetBIOS Name',
    key: 'ast_netbios_name',
  },
];

export const  ipTypeOptions = [
  { key: 'IPv4', value: 'IPv4', label: 'IPv4' },
  { key: 'IPv6', value: 'IPv6', label: 'IPv6' },
];

export const integratedOptions = [
  { key: 'Yes', value: 'Yes', label: 'Yes' },
  { key: 'No', value: 'No', label: 'No' },
];

export const employeeTypeOptions = [
  { key: 3, value: 'Permanent', label: 'Permanent' },
  { key: 4, value: 'Permanent Full-time', label: 'Permanent Full-time' },
  { key: 5, value: 'Permanent Part-time', label: 'Permanent Part-time' },
  { key: 5, value: 'Contractor', label: 'Contractor' },
];

export const hostedOptions = [
  { key: 'In-house', value: 'In-house', label: 'In-house' },
  { key: 'Outsourced', value: 'Outsourced', label: 'Outsourced' },
];

export const documentIARVI = [
  { key: 0, value: '0', label: '0' },
  { key: 1, value: '1', label: '1' },
  { key: 2, value: '2', label: '2' },
  { key: 3, value: '3', label: '3' },
  { key: 4, value: '4', label: '4' },
  { key: 5, value: '5', label: '5' },
  { key: 6, value: '6', label: '6' },
];

export const documentIARVC = [
  { key: 0, value: '0', label: '0' },
  { key: 1, value: '1', label: '1' },
  { key: 2, value: '2', label: '2' },
  { key: 3, value: '3', label: '3' },
  { key: 4, value: '4', label: '4' },
  { key: 5, value: '5', label: '5' },
  { key: 6, value: '6', label: '6' },
];

export const appsStatusList = [
  {
    key: 'all',
    value: 'All',
  },
  {
    key: 'enable',
    value: 'Enable',
  },
  {
    key: 'disable',
    value: 'Disable',
  },
  {
    key: 'notConfig',
    value: 'Not Configured',
  },
];

export const allowedFileExtensions = ['png', 'jpg', 'jpeg' ,'gif', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'csv', 'eml', 'zip', 'rar', 'msg'];

